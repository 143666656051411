import { CustomerConfig } from '@leon-hub/api-sdk';
import { OddsType } from '../enums';
export function getStringValueFromOddTypeConfig() {
    let customerConfigOddsType = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : CustomerConfig.IS_ODDS_TYPE_CLASSIC;
    switch(customerConfigOddsType){
        case CustomerConfig.IS_ODDS_TYPE_AMERICAN:
            return OddsType.AMERICA;
        case CustomerConfig.IS_ODDS_TYPE_CLASSIC:
        default:
            return OddsType.CLASSIC;
    }
}
