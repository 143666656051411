import { SportlineType } from 'web/src/modules/sportline/enums';
export function buildPostMatchStatisticsFromSportlineEvent(param) {
    let { sportlineEvent, sport } = param;
    if (!sportlineEvent || !sport) return null;
    return {
        id: sportlineEvent.id,
        matchDate: sportlineEvent.kickoff,
        family: sport.representation.family,
        resultScore: sportlineEvent.type === SportlineType.Live ? [
            sportlineEvent.matchProgress.totalScore.host,
            sportlineEvent.matchProgress.totalScore.guest
        ].join(':') : null,
        cards: [],
        substitutions: [],
        teams: sportlineEvent.competitors.map((competitor)=>({
                id: competitor.id,
                name: competitor.name,
                logo: competitor.logo
            })),
        goals: [],
        scores: [],
        periods: []
    };
}
