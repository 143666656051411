import { logger } from '@leon-hub/logging';
import { CompetitorType, BetlineStatisticCardType, BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { GoalType, IncidentType, TimelineMarkType, TimelineMarkCompetitorType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import { getMatchPercentageForTime, getWholeMatchTimeForSportFamily } from 'web/src/modules/sportline/utils/timeline';
const competitorTypeMapping = {
    [CompetitorType.Host]: TimelineMarkCompetitorType.HOST,
    [CompetitorType.Guest]: TimelineMarkCompetitorType.GUEST
};
const cardColorMapping = {
    [BetlineStatisticCardType.Yellow]: IncidentType.CARD_YELLOW,
    [BetlineStatisticCardType.Red]: IncidentType.CARD_RED,
    [BetlineStatisticCardType.YellowRed]: IncidentType.CARD_RED
};
export function parseCardToMark(card, param) {
    let { sportFamily } = param;
    if (!card.time || !card.team || !card.color) return null;
    try {
        const time = parseInt(card.time, 10);
        if (Number.isNaN(time)) return null;
        const position = getMatchPercentageForTime(sportFamily, time);
        if (null === position) return null;
        const type = cardColorMapping[card.color];
        if (!type) return null;
        const competitorType = competitorTypeMapping[card.team];
        if (!competitorType) return null;
        const label = `${card.time}’`;
        const incident = {
            type,
            position,
            competitorType,
            time,
            label,
            incomingPlayer: {
                name: card.playerDto?.playerName || '',
                nameShort: card.playerDto?.playerShortName || ''
            },
            outgoingPlayer: null
        };
        return {
            position,
            label,
            time,
            type: TimelineMarkType.INCIDENTS,
            hostIncidents: competitorType === TimelineMarkCompetitorType.HOST ? [
                incident
            ] : [],
            guestIncidents: competitorType === TimelineMarkCompetitorType.GUEST ? [
                incident
            ] : []
        };
    } catch (error) {
        logger.error(`Couldn't parse match timeline card mark: ${error}`);
        return null;
    }
}
export function parseGoalToMark(goal, param) {
    let { sportFamily } = param;
    if (!goal.time || !goal.team) return null;
    try {
        const time = parseInt(goal.time, 10);
        if (Number.isNaN(time)) return null;
        const position = getMatchPercentageForTime(sportFamily, time);
        if (null === position) return null;
        const competitorType = competitorTypeMapping[goal.team];
        if (!competitorType) return null;
        let type = IncidentType.GOAL;
        if (goal.disabled) type = IncidentType.DISABLED_GOAL;
        else if (goal.type === GoalType.OWN_GOAL) type = IncidentType.OWN_GOAL;
        const label = `${goal.time}’`;
        const incident = {
            type,
            position,
            competitorType,
            time,
            label,
            incomingPlayer: {
                name: goal.playerDto?.playerName || '',
                nameShort: goal.playerDto?.playerShortName || ''
            },
            outgoingPlayer: null
        };
        return {
            position,
            label,
            time,
            type: TimelineMarkType.INCIDENTS,
            hostIncidents: competitorType === TimelineMarkCompetitorType.HOST ? [
                incident
            ] : [],
            guestIncidents: competitorType === TimelineMarkCompetitorType.GUEST ? [
                incident
            ] : []
        };
    } catch (error) {
        logger.error(`Couldn't parse match timeline goal mark: ${error}`);
        return null;
    }
}
export function parseSubstitutionToMark(substitution, param) {
    let { sportFamily } = param;
    if (!substitution.time || !substitution.team) return null;
    try {
        const time = parseInt(substitution.time, 10);
        if (Number.isNaN(time)) return null;
        const position = getMatchPercentageForTime(sportFamily, time);
        if (null === position) return null;
        const competitorType = competitorTypeMapping[substitution.team];
        if (!competitorType) return null;
        const type = IncidentType.SUBSTITUTION;
        const label = `${substitution.time}’`;
        const incident = {
            type,
            position,
            competitorType,
            time,
            label,
            incomingPlayer: {
                name: substitution.incoming?.playerName || '',
                nameShort: substitution.incoming?.playerShortName || ''
            },
            outgoingPlayer: substitution.outgoing ? {
                name: substitution.outgoing.playerName || '',
                nameShort: substitution.outgoing.playerShortName || ''
            } : null
        };
        return {
            position,
            label,
            time,
            type: TimelineMarkType.INCIDENTS,
            hostIncidents: competitorType === TimelineMarkCompetitorType.HOST ? [
                incident
            ] : [],
            guestIncidents: competitorType === TimelineMarkCompetitorType.GUEST ? [
                incident
            ] : []
        };
    } catch (error) {
        logger.error(`Couldn't parse match timeline goal mark: ${error}`);
        return null;
    }
}
export function parseScoreToMark(score, param) {
    let { sportFamily } = param;
    if (!score.type) return [];
    const wholeMatchTime = getWholeMatchTimeForSportFamily(sportFamily);
    if (!wholeMatchTime) return [];
    if (score.type === BetlineStatisticScoresType.HalfTime) return [
        {
            position: 50,
            time: wholeMatchTime / 2,
            type: TimelineMarkType.HALF_TIME,
            label: String(score.value),
            hostIncidents: [],
            guestIncidents: []
        }
    ];
    if (score.type === BetlineStatisticScoresType.FullTime) return [
        {
            position: 100,
            time: wholeMatchTime,
            type: TimelineMarkType.FULL_TIME,
            label: String(score.value),
            hostIncidents: [],
            guestIncidents: []
        }
    ];
    if (score.type === BetlineStatisticScoresType.OverTime) return [
        {
            position: getMatchPercentageForTime(sportFamily, wholeMatchTime + 15) || 115,
            time: wholeMatchTime + 15,
            type: TimelineMarkType.HALF_EXTRA_TIME,
            label: '',
            hostIncidents: [],
            guestIncidents: []
        },
        {
            position: getMatchPercentageForTime(sportFamily, wholeMatchTime + 30) || 130,
            time: wholeMatchTime + 30,
            type: TimelineMarkType.FULL_EXTRA_TIME,
            label: String(score.value),
            hostIncidents: [],
            guestIncidents: []
        }
    ];
    if (score.type === BetlineStatisticScoresType.AfterPenalty) return [
        {
            position: 200,
            time: 2 * wholeMatchTime,
            type: TimelineMarkType.PENALTY_SHOOTOUT,
            label: String(score.value),
            hostIncidents: [],
            guestIncidents: []
        }
    ];
    return [];
}
