export * from './StatisticColoredLabel';
export * from './StatisticChartBar';
export var TimelineMarkType;
var TimelineMarkType1;
(TimelineMarkType1 = TimelineMarkType || (TimelineMarkType = {}))[TimelineMarkType1["HALF_TIME"] = 1] = "HALF_TIME", TimelineMarkType1[TimelineMarkType1["FULL_TIME"] = 2] = "FULL_TIME", TimelineMarkType1[TimelineMarkType1["HALF_EXTRA_TIME"] = 3] = "HALF_EXTRA_TIME", TimelineMarkType1[TimelineMarkType1["FULL_EXTRA_TIME"] = 4] = "FULL_EXTRA_TIME", TimelineMarkType1[TimelineMarkType1["PENALTY_SHOOTOUT"] = 5] = "PENALTY_SHOOTOUT", TimelineMarkType1[TimelineMarkType1["INCIDENTS"] = 6] = "INCIDENTS";
export var IncidentType;
var IncidentType1;
(IncidentType1 = IncidentType || (IncidentType = {}))[IncidentType1["GOAL"] = 1] = "GOAL", IncidentType1[IncidentType1["OWN_GOAL"] = 2] = "OWN_GOAL", IncidentType1[IncidentType1["DISABLED_GOAL"] = 3] = "DISABLED_GOAL", IncidentType1[IncidentType1["CARD_YELLOW"] = 4] = "CARD_YELLOW", IncidentType1[IncidentType1["CARD_RED"] = 5] = "CARD_RED", IncidentType1[IncidentType1["SUBSTITUTION"] = 6] = "SUBSTITUTION";
export var TimelineMarkCompetitorType;
var TimelineMarkCompetitorType1;
(TimelineMarkCompetitorType1 = TimelineMarkCompetitorType || (TimelineMarkCompetitorType = {}))[TimelineMarkCompetitorType1["HOST"] = 1] = "HOST", TimelineMarkCompetitorType1[TimelineMarkCompetitorType1["GUEST"] = 2] = "GUEST";
export var GoalType;
var GoalType1;
(GoalType1 = GoalType || (GoalType = {}))["HEADER"] = "Header", GoalType1["PENALTY"] = "Penalty", GoalType1["OWN_GOAL"] = "Own goal", GoalType1["EMPTY"] = "0";
