import { useLocalStorageManager } from '@leon-hub/local-storage';
export function useSaveToLocalStorage() {
    const localStorageManager = useLocalStorageManager();
    function saveValueToLocalStorage(key, value) {
        if (value) localStorageManager.setItem(key, String(value));
        else localStorageManager.removeItem(key);
    }
    function getValueFromLocalStorage(key) {
        return localStorageManager.getItem(key);
    }
    return {
        saveValueToLocalStorage,
        getValueFromLocalStorage
    };
}
