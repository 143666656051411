import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import SportlineDefaultHeader from 'web/src/modules/sportline/components/header/SportlineHeader.vue';
import SportlineBetSwipeHeader from 'web/src/modules/sportline/views/bet-swipe/headline/CustomHeader.vue';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import RelatedSportEventsTable from 'web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsTable.vue';
import SportSectionHorizontal from 'web/src/modules/sportline/views/bet-swipe/list/horizontal/EventsListSportSection.vue';
import SportSectionVertical from 'web/src/modules/sportline/views/bet-swipe/list/vertical/EventsListSportSection.vue';
import { useSimilarEventsSection } from './useSimilarEventsSection';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SimilarEventsSection',
    setup (__props) {
        /**
 * A block on the bottom of the page.
 * It contains a related events list.
 */ const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        const { sportElement, isDisplayBlock, isDisplayTitle, activeEventId, isOnlyOutrightShown } = useSimilarEventsSection({
            doNotSplitOutrightList: computed(()=>false)
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isDisplayBlock) ? _withDirectives((_openBlock(), _createBlock(VLazyRender, {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['similar-events-section']]: true,
                    [_ctx.$style['similar-events-section--cards']]: void 0
                })
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(isDisplayTitle) ? (_openBlock(), _createBlock(SportlineComingHeader, {
                                key: 0,
                                caption: _ctx.$t('JSP_EVENTS_SIMILAR')
                            }, null, 8, [
                                "caption"
                            ])) : _createCommentVNode("", true),
                            _createVNode(RelatedSportEventsTable, {
                                "without-headline": "",
                                "active-id": _unref(activeEventId),
                                "sport-element": _unref(sportElement),
                                "is-only-outright-shown": _unref(isOnlyOutrightShown)
                            }, null, 8, [
                                "active-id",
                                "sport-element",
                                "is-only-outright-shown"
                            ])
                        ], 64))
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'SimilarEventsSection'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
