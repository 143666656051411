import { computed } from 'vue';
export function useBetlineSettings(props) {
    const { sportlineBlock } = props;
    const isLiveOutrightsEnabled = computed(()=>!!sportlineBlock.value?.liveOutrightsEnabled);
    const isExtraLiveEnabled = computed(()=>!!sportlineBlock.value?.virtualSportLiveEventsEnabled);
    const isUpcomingEventsEnabled = computed(()=>!!sportlineBlock.value?.displayPrematchEventsInLiveEnabled);
    return {
        isLiveOutrightsEnabled,
        isExtraLiveEnabled,
        isUpcomingEventsEnabled
    };
}
