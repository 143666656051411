import { computed, toRef } from 'vue';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import DateTime from 'web/src/utils/DateTime';
export function usePostMatchHeadlineInfo(props) {
    const { kickoff } = props;
    const settingsStore = useSportlineSettingsStore();
    const datePattern = toRef(settingsStore, 'sportEventDetailsDatePattern');
    const { toFullLocaleDate } = useDateLocalizers();
    const date = computed(()=>kickoff.value ? toFullLocaleDate(kickoff.value, datePattern.value) : null);
    const time = computed(()=>kickoff.value ? DateTime.withTimeStamp(kickoff.value).toTime() : null);
    return {
        date,
        time
    };
}
