import { defineStore } from 'pinia';
import { useIsDebugEnabled, useDebugVirtualListRendererStoreComposable } from './composables';
export const useSportlineDebugStore = defineStore('sportline-debug', ()=>{
    const { isDebugEnabled } = useIsDebugEnabled();
    const virtualListRenderer = useDebugVirtualListRendererStoreComposable({
        isDebugEnabled
    });
    virtualListRenderer.loadVirtualListRendererEnabled();
    return {
        isDebugEnabled,
        ...virtualListRenderer
    };
});
