import { defineStore } from 'pinia';
import { ref, computed, toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { SportFamily, sportFamilyValues, SportSegmentId } from 'web/src/modules/sportline/enums';
import defaultSportSegmentsSettings from 'web/src/modules/sportline/store/consts/defaultSportSegmentSettings';
import { useSportlineDebugStore } from 'web/src/modules/sportline/submodules/debug';
import { useBuildSportSettings } from './composables/useBuildSportSettings';
import { useSportlineLayoutSettingsStoreComposable } from './composables/useSportlineLayoutSettings';
import { useSportlineStatisticsSettingsStoreComposable } from './composables/useSportlineStatisticsSettings';
import { useBetlineFlagsSettings } from './composables/useBetlineFlagsSettings';
import { useBetlineSettings } from './composables/useBetlineSettings';
import { useWidgetsSettings } from './composables/useWidgetsSettings';
// eslint-disable-next-line sonarjs/cognitive-complexity
const useSportlineSettingsStore = defineStore('sportline-settings', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const sportlineDebugStore = useSportlineDebugStore();
    const sportlineEventsBlock = toRef(siteConfigStore, 'sportEventsBlock');
    const sportlineBlock = toRef(siteConfigStore, 'sportLineBlock');
    const widgetsBlock = toRef(siteConfigStore, 'widgetsBlock');
    const layoutSettings = useSportlineLayoutSettingsStoreComposable();
    const { isBetSwipeTopEventsLayoutEnabled } = layoutSettings;
    const statisticsSettings = useSportlineStatisticsSettingsStoreComposable({
        sportlineBlock
    });
    const betlineFlagsSettings = useBetlineFlagsSettings({
        sportlineBlock
    });
    const betlineSettings = useBetlineSettings({
        sportlineBlock
    });
    const buildSportSettings = useBuildSportSettings({
        sportlineBlock
    });
    const widgetsSettings = useWidgetsSettings({
        sportlineBlock,
        widgetsBlock
    });
    const { isCybersportV2Enabled, sportFamilyMapping } = buildSportSettings;
    const { isUpcomingEventsEnabled } = betlineSettings;
    // State
    const sportSegmentRepresentationSettings = ref(Object.freeze({
        [SportSegmentId.CyberSport]: {
            streamAspectRatio: 9 / 16
        }
    }));
    // Getters
    /** mobile.sportline.update.interval */ const sportLineUpdateInterval = computed(()=>{
        const interval = sportlineEventsBlock.value?.sportLineUpdateInterval || 10;
        return 1000 * interval;
    });
    /** web2.sportline.sidebar.sports.update.interval */ const sportLineSidebarSportsUpdateInterval = computed(()=>sportlineEventsBlock.value?.sportLineSidebarSportsUpdateInterval ?? null);
    // web2.sportline.prematch.events.update.interval
    const sportLinePrematchUpdateInterval = computed(()=>sportlineEventsBlock.value?.sportLinePrematchUpdateInterval || 60000);
    const isListMarketsDropDownEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.listMarketsDropdownEnabled);
    const listMarketsDropDownLimit = computed(()=>siteConfigStore.sportLineBlock?.listMarketsDropdownLimit ?? 0);
    const isUsedMoreRunnersCount = computed(()=>!!siteConfigStore.sportLineBlock?.runnersCountDisplayEnabled);
    const doRemoveDuplicateEvents = computed(()=>!!siteConfigStore.sportLineBlock?.doRemoveDuplicateEvents);
    const isFilterTransitionFadeEffectEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.filterTransitionFadeEffectEnabled);
    const filterTransitionDuration = computed(()=>({
            fadeOut: siteConfigStore.sportLineBlock?.filterTransitionFadeOutDuration || 200,
            fadeIn: siteConfigStore.sportLineBlock?.filterTransitionFadeInDuration || 250
        }));
    const maxTopLeaguesInTab = computed(()=>siteConfigStore.sportLineBlock?.maxTopLeaguesInTab || 5);
    const maxTopLeaguesTabsInSidebar = computed(()=>siteConfigStore.sportLineBlock?.maxTopLeaguesTabsInSidebar || 6);
    /** Do use minutes format instead timestamp for requests */ const doUseRelativeTimeForSportline = computed(()=>!!siteConfigStore.sportLineBlock?.doUseRelativeTimeForSportline);
    const isMarketGroupsTabsEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.marketGroupsTabsEnabled);
    const isMarketTypesSelectionEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.marketTypesSelectionEnabled);
    const isTimeReversedIndicatorAvailable = computed(()=>!!siteConfigStore.sportLineBlock?.timeReversedIndicatorEnabled);
    const isSportFiltersEnabled = computed(()=>!!sportlineEventsBlock.value?.sportFiltersEnabled);
    const sportEventDetailsDatePattern = computed(()=>siteConfigStore.sportLineBlock?.eventDateFormat || void 0);
    const isTopEventsEnabled = computed(()=>// there is no version for card and this setting
        isBetSwipeTopEventsLayoutEnabled.value || !!siteConfigStore.sportLineBlock?.topEventsEnabled);
    const isTopEventsLogosEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.topEventsLogosEnabled);
    const isPrematchFavoriteEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.prematchFavoriteEnabled);
    const isEventChangeSubscriptionEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.eventChangeSubscriptionEnabled);
    const relatedEventsLimit = computed(()=>siteConfigStore.sportLineBlock?.relatedEventsLimit || 0);
    const familyFilterResetEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.familyFilterResetEnabled);
    const isNoMarketsBlockedBtnEnabled = computed(()=>!!siteConfigStore.sportLineBlock?.noMarketsBlockedBtnEnabled);
    const isVirtualListEnabled = computed(()=>sportlineDebugStore.isVirtualListRendererEnabled || !!siteConfigStore.sportLineBlock?.virtualListEnabled);
    // @TODO remove default sport family from list
    const allowedSportFamily = computed(()=>{
        const allSportFamily = Object.values(sportFamilyMapping.value);
        // eslint-disable-next-line unicorn/no-new-array,unicorn/prefer-spread
        return [
            ...new Set(new Array(0).concat(allSportFamily, sportFamilyValues))
        ];
    });
    const sportSegmentsSettings = computed(()=>{
        const sportlineSettings = sportlineBlock.value;
        const sportEventsSettings = sportlineEventsBlock.value;
        // @TODO get all from scg
        return {
            default: defaultSportSegmentsSettings,
            [RouteName.SPORTLINE_LIVE_EVENTS]: {
                isPageEnabled: true,
                // web2.sportline.live.events.update.interval
                liveUpdateInterval: sportEventsSettings?.sportLineLiveUpdateInterval ?? 60000,
                // web2.sportline.filter.activated.sport.update.interval
                liveActiveEntityUpdateInterval: sportEventsSettings?.activatedSportUpdateInterval ?? 10000,
                isUpdateOnlyActiveEntityEnabled: sportlineSettings?.onlyActivatedSportUpdateEnabled ?? false,
                isUpdateOnlyActiveEntityOnChangeEnabled: sportlineSettings?.onlyActivatedSportUpdateOnChangeEnabled ?? false,
                isComingEnabled: sportEventsSettings?.comingEventsEnabled ?? false,
                comingUpdateInterval: sportLinePrematchUpdateInterval.value,
                comingKickoffFilter: sportEventsSettings?.comingEventsKickoffFilter ?? SportFilterKickoffPeriod.THREEHOURS,
                maximumLiveToShowCompiledComingBlock: 20,
                maximumEntitiesInCompiledComingBlock: 5,
                maximumComingEventsInEntityInCompiledBlock: 3,
                maximumComingEventsInEntityTab: sportEventsSettings?.comingEventsLimit ?? 5,
                isUpcomingEventsEnabled: isUpcomingEventsEnabled.value
            },
            [RouteName.SPORTLINE_CYBERSPORT]: {
                isPageEnabled: isCybersportV2Enabled.value,
                sportFamily: SportFamily.ESport,
                // @TODO custom property
                liveUpdateInterval: sportEventsSettings?.sportLineLiveUpdateInterval ?? 60000,
                // @TODO custom property
                liveActiveEntityUpdateInterval: sportEventsSettings?.activatedSportUpdateInterval ?? 10000,
                isUpdateOnlyActiveEntityEnabled: true,
                isUpdateOnlyActiveEntityOnChangeEnabled: true,
                isComingEnabled: true,
                comingUpdateInterval: sportLinePrematchUpdateInterval.value,
                comingKickoffFilter: SportFilterKickoffPeriod.ALL,
                maximumLiveToShowCompiledComingBlock: null,
                maximumEntitiesInCompiledComingBlock: 5,
                maximumComingEventsInEntityInCompiledBlock: 3,
                maximumComingEventsInEntityTab: null,
                isUpcomingEventsEnabled: false
            }
        };
    });
    return {
        sportLineUpdateInterval,
        sportLineSidebarSportsUpdateInterval,
        sportLinePrematchUpdateInterval,
        isListMarketsDropDownEnabled,
        listMarketsDropDownLimit,
        isFilterTransitionFadeEffectEnabled,
        filterTransitionDuration,
        maxTopLeaguesInTab,
        maxTopLeaguesTabsInSidebar,
        doUseRelativeTimeForSportline,
        isMarketGroupsTabsEnabled,
        isMarketTypesSelectionEnabled,
        isUsedMoreRunnersCount,
        doRemoveDuplicateEvents,
        isTimeReversedIndicatorAvailable,
        sportSegmentRepresentationSettings,
        isSportFiltersEnabled,
        sportEventDetailsDatePattern,
        isTopEventsEnabled,
        isTopEventsLogosEnabled,
        isPrematchFavoriteEnabled,
        isEventChangeSubscriptionEnabled,
        relatedEventsLimit,
        familyFilterResetEnabled,
        isNoMarketsBlockedBtnEnabled,
        isVirtualListEnabled,
        allowedSportFamily,
        sportSegmentsSettings,
        ...buildSportSettings,
        ...betlineFlagsSettings,
        ...betlineSettings,
        ...statisticsSettings,
        ...layoutSettings,
        ...widgetsSettings
    };
});
export default useSportlineSettingsStore;
