import { ref, computed } from 'vue';
import { useSaveToLocalStorage } from './useSaveToLocalStorage';
const LS_VIRTUAL_LIST_RENDERER_KEY = 'SPORTLINE_VIRTUAL_LIST_RENDERER';
export function useDebugVirtualListRendererStoreComposable(param) {
    let { isDebugEnabled } = param;
    const { saveValueToLocalStorage, getValueFromLocalStorage } = useSaveToLocalStorage();
    const isVirtualListRendererEnabled = ref(false);
    return {
        isVirtualListRendererEnabled: computed(()=>isDebugEnabled.value && isVirtualListRendererEnabled.value),
        setVirtualListRendererEnabled (value) {
            isVirtualListRendererEnabled.value = value;
        },
        saveVirtualListRendererEnabled () {
            saveValueToLocalStorage(LS_VIRTUAL_LIST_RENDERER_KEY, isVirtualListRendererEnabled.value);
        },
        loadVirtualListRendererEnabled () {
            if (!isDebugEnabled.value) return;
            const rawData = getValueFromLocalStorage(LS_VIRTUAL_LIST_RENDERER_KEY);
            if (rawData) isVirtualListRendererEnabled.value = 'true' === rawData;
        }
    };
}
