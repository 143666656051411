import { toRef, computed } from 'vue';
import { SportsbookUIVersion } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export function useSportlineLayoutSettingsStoreComposable() {
    const siteConfigStore = useSiteConfigStore();
    const sportsbookUIVersion = toRef(siteConfigStore, 'sportsbookUIVersion');
    return {
        isBetSwipeTopEventsLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUITopPageVersion === SportsbookUIVersion.V1),
        isBetSwipeNavigationLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUINavigationVersion === SportsbookUIVersion.V1),
        isBetSwipeLeagueLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUILeaguePageVersion === SportsbookUIVersion.V1),
        isBetSwipeRegionLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUIRegionPageVersion === SportsbookUIVersion.V1),
        isBetSwipeSportsLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUISportsPageVersion === SportsbookUIVersion.V1),
        isBetSwipeEventDetailsLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUIEventDetailsPageVersion === SportsbookUIVersion.V1),
        isBetSwipeLiveLayoutEnabled: computed(()=>sportsbookUIVersion.value?.sportsbookUILivePageVersion === SportsbookUIVersion.V1)
    };
}
