/* eslint-disable class-methods-use-this */ import { watch, toRef, nextTick } from 'vue';
import { isArray } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';
import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
let CybersportPageSeoController = class CybersportPageSeoController extends DefaultPageSeoController {
    getSeoConfig(router, from, to) {
        return new Promise((resolve, reject)=>{
            const cybersportStore = useSportlineCybersportStore();
            const activeRegionElement = toRef(cybersportStore, 'activeRegionElement');
            let unwatch = watch(activeRegionElement, async (value)=>{
                const regionId = value?.region.id;
                const regionName = (value?.region.navigationParameters.urlName || '').toLowerCase();
                const routeRegionParam = to.params?.region || '';
                const routeRegionName = (isArray(routeRegionParam) ? routeRegionParam.join(',') : routeRegionParam).toLowerCase();
                if (regionName === routeRegionName) {
                    // await fix error while immediate fired callback
                    await nextTick();
                    unwatch?.();
                    unwatch = void 0;
                    const params = regionId ? {
                        ...to.params || {},
                        id: regionId
                    } : to.params;
                    resolve({
                        ...to,
                        params
                    });
                }
            }, {
                immediate: true
            });
            Timer.setTimeout(()=>{
                unwatch?.();
                unwatch = void 0;
                reject(new Error('CybersportPageSeoController: await active region element timeout'));
            }, 5000);
        });
    }
};
export { CybersportPageSeoController as default };
