import { computed } from 'vue';
export function useFirstElementsInSportElement(props) {
    const { sportElement } = props;
    const regionElement = computed(()=>sportElement.value?.regions[0] ?? null);
    const leagueElement = computed(()=>regionElement.value?.leagues[0] ?? null);
    return {
        regionElement,
        leagueElement
    };
}
export function useFirstLeagueInSportElement(props) {
    const { sportElement } = props;
    const sport = computed(()=>sportElement.value?.sport ?? null);
    const region = computed(()=>sportElement.value?.regions[0]?.region ?? null);
    const league = computed(()=>sportElement.value?.regions[0]?.leagues[0]?.league ?? null);
    return {
        sport,
        region,
        league
    };
}
