import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export function getSportlineNavigationRouteComponent() {
    const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();
}
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export function getSportlineDetailsPageNavigationRouteComponent() {
    const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();
}
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export function getSportlineNavigationLoaderRouteComponent() {
    return ()=>import('./views/NavigationMainContentLoaderRouteComponent/NavigationMainContentLoaderRouteComponent.vue');
}
