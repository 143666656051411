import { computed } from 'vue';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
/*
 * Extract sport from SportEventDetails object
 */ export function useSportlineEventSport(props) {
    const sport = computed(()=>props.sportlineEvent.value?.sport);
    const { backgroundColor } = useSportBackgroundColor({
        sport
    });
    const isDefaultSport = computed(()=>!backgroundColor.value);
    return {
        sport,
        backgroundColor,
        isDefaultSport
    };
}
