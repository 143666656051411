import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
const defaultSportSegmentsSettings = {
    isPageEnabled: true,
    liveUpdateInterval: 60000,
    liveActiveEntityUpdateInterval: 60000,
    isUpdateOnlyActiveEntityEnabled: false,
    isUpdateOnlyActiveEntityOnChangeEnabled: false,
    isComingEnabled: false,
    comingUpdateInterval: 60000,
    comingKickoffFilter: SportFilterKickoffPeriod.THREEHOURS,
    maximumLiveToShowCompiledComingBlock: null,
    maximumEntitiesInCompiledComingBlock: null,
    maximumComingEventsInEntityInCompiledBlock: null,
    maximumComingEventsInEntityTab: null,
    isUpcomingEventsEnabled: false
};
export default defaultSportSegmentsSettings;
