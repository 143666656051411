import { toRef } from 'vue';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
export function useSportlineLayoutSettings() {
    const sportlineSettingsStore = useSportlineSettingsStore();
    const isBetSwipeTopEventsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeTopEventsLayoutEnabled');
    const isBetSwipeNavigationLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeNavigationLayoutEnabled');
    const isBetSwipeLeagueLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeLeagueLayoutEnabled');
    const isBetSwipeRegionLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeRegionLayoutEnabled');
    const isBetSwipeLiveLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeLiveLayoutEnabled');
    const isBetSwipeEventDetailsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeEventDetailsLayoutEnabled');
    const isBetSwipeSportsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeSportsLayoutEnabled');
    return {
        isBetSwipeTopEventsLayoutEnabled,
        isBetSwipeNavigationLayoutEnabled,
        isBetSwipeLeagueLayoutEnabled,
        isBetSwipeRegionLayoutEnabled,
        isBetSwipeLiveLayoutEnabled,
        isBetSwipeEventDetailsLayoutEnabled,
        isBetSwipeSportsLayoutEnabled
    };
}
