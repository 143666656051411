import { isString } from '@leon-hub/guards';
export default function isOptionalFilterSportFamily(value, allowedSportFamily) {
    let customFilterValues = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    if (!value) return true;
    if (!isString(value)) return false;
    const activeFilter = value.toLowerCase();
    return [
        ...allowedSportFamily,
        ...customFilterValues
    ].map((filterValue)=>filterValue.toLowerCase()).includes(activeFilter);
}
