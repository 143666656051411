import RouteName from '@leon-hub/routing-config';
import { HomePageType } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { getSportlineNavigationRouteComponent, getSportlineNavigationLoaderRouteComponent, getSportlineDetailsPageNavigationRouteComponent } from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';
import { getSportlineNavigationLeftSidebarRouteComponent } from 'web/src/modules/sportline/submodules/sidebar/SportlineSidebarSubmodule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export { SportlineSportsModule } from './submodules/sports/SportlineSportsModule';
export class SportlineModule extends AppModule {
    // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
    addRoutes(router) {
        const { isBetSwipeTopEventsLayoutEnabled, isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();
        // shared components
        const navigation = getSportlineNavigationRouteComponent();
        const leftSideBar = getSportlineNavigationLeftSidebarRouteComponent();
        const contentLoader = getSportlineNavigationLoaderRouteComponent();
        router.addDefaultRoute({
            name: RouteName.SPORTLINE_LIVE_EVENTS,
            path: '/live/:family?',
            prefetch: ()=>import('web/src/modules/sportline/submodules/live/prefetch/LivePagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/live/views/LiveRoutePage.vue'),
            navigation,
            leftSideBar,
            contentLoader,
            topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue')
        });
        router.addDefaultRoute({
            name: RouteName.SPORT_REGION,
            path: '/bets/:sportFamily/:id-:urlName',
            prefetch: ()=>import('web/src/modules/sportline/submodules/region/prefetch/RegionPagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/region/views/RegionRoutePage.vue'),
            topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
            navigation,
            leftSideBar,
            contentLoader
        });
        router.addDefaultRoute({
            name: RouteName.SPORT_LEAGUE,
            path: '/bets/:sportFamily/:regionUrlName/:id-:urlName',
            prefetch: ()=>import('web/src/modules/sportline/submodules/league/prefetch/LeaguePagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/league/views/LeagueRoutePage.vue'),
            topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
            navigation,
            leftSideBar,
            contentLoader
        });
        // with RouteName.SPORT_LEAGUE canonical
        // with RouteName.SPORT_LEAGUE canonical
        router.addDefaultRoute({
            name: RouteName.SPORTLINE_CYBERSPORT,
            path: '/cybersport/:region?',
            prefetch: ()=>import('web/src/modules/sportline/submodules/cybersport/prefetch/CybersportPagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/cybersport/views/CybersportRoutePage.vue'),
            seoController: ()=>import('web/src/modules/sportline/submodules/cybersport/seo/CybersportPageSeoController'),
            navigation,
            leftSideBar,
            contentLoader
        });
        router.addDefaultRoute({
            name: RouteName.SPORT_EVENT_DETAILS,
            path: '/bets/:sportFamily/:regionUrlName/:leagueUrlName/:id-:urlName',
            prefetch: ()=>import('web/src/modules/sportline/submodules/event-details/prefetch/SportEventDetailsPagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsRoutePage.vue'),
            fixedBar: ()=>import('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsFixedBarRouteComponent.vue'),
            topAuthorizedSlot: ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
            navigation: getSportlineDetailsPageNavigationRouteComponent(),
            leftSideBar: void 0,
            contentLoader,
            meta: {
                resetScrollPosition: true
            }
        });
        router.addDefaultRoute({
            name: RouteName.SPORTRADAR_STATISTICS_WIDGET,
            path: '/statistics',
            prefetch: ()=>import('web/src/modules/sportline/submodules/widgets/prefetch/SportradarStatisticsWidgetPrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/widgets/pages/SportradarWidgetRoutePage/SportradarWidgetRoutePage.vue'),
            navigation,
            leftSideBar,
            contentLoader,
            props: {
                type: 'statistics'
            }
        });
        router.addDefaultRoute({
            name: RouteName.SPORTRADAR_LIVESCORE_WIDGET,
            path: '/livescore',
            prefetch: ()=>import('web/src/modules/sportline/submodules/widgets/prefetch/SportradarLiveScoreWidgetPrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/widgets/pages/SportradarWidgetRoutePage/SportradarWidgetRoutePage.vue'),
            navigation,
            leftSideBar,
            contentLoader,
            props: {
                type: 'livescore'
            }
        });
        "1";
        router.addDefaultRoute({
            name: RouteName.SPORTLINE_VIRTUAL_SPORT,
            path: '/virtual-sport/:sport?',
            prefetch: ()=>import('web/src/modules/sportline/submodules/virtual-sport/prefetch/VirtualSportPagePrefetch'),
            component: ()=>import('web/src/modules/sportline/submodules/virtual-sport/views/VirtualSportRoutePage.vue'),
            // eslint-disable-next-line max-len
            seoController: ()=>import('web/src/modules/sportline/submodules/virtual-sport/seo/VirtualSportPageSeoController'),
            navigation,
            leftSideBar,
            contentLoader
        });
        "1";
        {
            const { homePageType } = useSiteConfigStore();
            if (homePageType !== HomePageType.SPORTS) router.addDefaultRoute({
                name: RouteName.SPORTLINE_TOP_EVENTS,
                path: '/top',
                prefetch: ()=>import('web/src/modules/home/submodules/sportline/prefetch/HomePagePrefetch'),
                component: ()=>import('web/src/modules/home/submodules/sportline/views/HomeRoutePage.vue'),
                navigation,
                leftSideBar,
                contentLoader,
                topAuthorizedSlot: homePageType === HomePageType.CUSTOM ? ()=>import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue') : void 0
            });
            else router.addBlankRoute({
                name: RouteName.SPORTLINE_TOP_EVENTS,
                path: '/top/:family?',
                component: ()=>import('web/src/modules/errors/pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue')
            });
        }
        "1";
        router.addModalRoute({
            name: RouteName.SPORTLINE_SELECT_ODDS,
            path: '/odds',
            title: '{{$t(\'WEB2_ODDS\')}}',
            component: ()=>import('web/src/modules/sportline/submodules/odds/views/OddsRoutePage.vue'),
            modalPreset: ModalDesktopPreset.SmallDesktopModal
        });
    }
}
