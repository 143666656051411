import { computed } from 'vue';
export function useWidgetsSettings(props) {
    const { sportlineBlock, widgetsBlock } = props;
    const isLiveStreamEnabled = computed(()=>!!sportlineBlock.value?.liveStreamEnabled);
    const isSportradarLiveMatchTrackerEnabled = computed(()=>!!widgetsBlock.value?.sportradarLiveMatchTrackerEnabled);
    const isBetGeniusWidgetEnabled = computed(()=>!!sportlineBlock.value?.betgeniusWidgetEnabled);
    const isLSportsWidgetEnabled = computed(()=>!!sportlineBlock.value?.lsportsWidgetEnabled);
    const isLiveStreamWidgetFloatingEnabled = computed(()=>!!sportlineBlock.value?.liveStreamWidgetFloatingEnabled);
    const isSportRadarWidgetFloatingEnabled = computed(()=>!!sportlineBlock.value?.sportRadarWidgetFloatingEnabled);
    return {
        isLiveStreamEnabled,
        isSportradarLiveMatchTrackerEnabled,
        isBetGeniusWidgetEnabled,
        isLSportsWidgetEnabled,
        isLiveStreamWidgetFloatingEnabled,
        isSportRadarWidgetFloatingEnabled
    };
}
