import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { TimelineMarkType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import { compareAsc } from 'web/src/utils/sort';
function fixPositionToBorders(position, min, max) {
    if (void 0 !== min && position < min) return min;
    if (void 0 !== max && position > max) return max;
    return position;
}
export function filterEmptyMark(mark) {
    return null !== mark;
}
export function filterSameTypeMarks(mark, index, list) {
    return list.findIndex((searched)=>searched.type === mark.type) === index;
}
export function sortMarksByAsc(marks) {
    return marks.sort((a, b)=>compareAsc(a.position, b.position));
}
export function marksApproximation(marks, options) {
    let currentMark = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null, result = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : [];
    const [nextMark, ...otherMarks] = marks;
    if (!nextMark) // last mark
    return currentMark ? [
        ...result,
        {
            ...currentMark,
            position: fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition)
        }
    ] : result;
    if (!currentMark) // first mark
    return marksApproximation(otherMarks, options, nextMark, result);
    const distance = Math.abs(currentMark.position - nextMark.position);
    if (distance < options.minDistance) {
        const currentPosition = fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition);
        const middle = (currentPosition + nextMark.position) / 2;
        const time = (currentMark.time + nextMark.time) / 2;
        const position = Math.round(10 * middle) / 10;
        const mergedMark = {
            ...currentMark,
            time,
            label: `${Math.round(time)}’`,
            position,
            hostIncidents: [
                ...currentMark.hostIncidents,
                ...nextMark.hostIncidents
            ],
            guestIncidents: [
                ...currentMark.guestIncidents,
                ...nextMark.guestIncidents
            ]
        };
        return marksApproximation(otherMarks, options, mergedMark, result);
    }
    return marksApproximation(otherMarks, options, nextMark, [
        ...result,
        {
            ...currentMark,
            position: fixPositionToBorders(currentMark.position, options.minPosition, options.maxPosition)
        }
    ]);
}
function getFirstTimeMarks(marks) {
    const filtered = marks.filter((mark)=>mark.position <= 50);
    return filtered.map((mark)=>({
            ...mark,
            position: 2 * mark.position
        }));
}
function getSecondTimeMarks(marks) {
    const filtered = marks.filter((mark)=>mark.position > 50 && mark.position <= 100);
    return filtered.map((mark)=>({
            ...mark,
            position: (mark.position - 50) * 2
        }));
}
function getExtraTimeMarks(marks) {
    const filtered = marks.filter((mark)=>mark.position > 100);
    return filtered.map((mark)=>({
            ...mark,
            position: (mark.position - 100) * 2
        }));
}
export function splitMarksByTimes(incomingMarks, scores) {
    const map = new Map([]);
    map.set(TimelineMarkType.HALF_TIME, getFirstTimeMarks(incomingMarks));
    map.set(TimelineMarkType.FULL_TIME, getSecondTimeMarks(incomingMarks));
    if (scores.some((score)=>score.type === BetlineStatisticScoresType.OverTime || score.type === BetlineStatisticScoresType.AfterPenalty)) map.set(TimelineMarkType.FULL_EXTRA_TIME, getExtraTimeMarks(incomingMarks));
    return [
        ...map.entries()
    ].map((param)=>{
        let [type, marks] = param;
        return {
            type,
            marks
        };
    });
}
