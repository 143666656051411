import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';
import { OddsType } from 'web/src/modules/sportline/submodules/odds/enums/OddsType';
export function useOddsSettings() {
    const sportlineBlock = toRef(useSiteConfigStore(), 'sportLineBlock');
    const isOddsFormatSwitcherEnabled = computed(()=>!!sportlineBlock.value?.oddsFormatSwitcherEnabled);
    const currentOddsType = toRef(useOddsStore(), 'currentOddsType');
    const computedOddsType = computed(()=>{
        if (isOddsFormatSwitcherEnabled.value) return currentOddsType.value;
        return OddsType.CLASSIC;
    });
    return {
        isOddsFormatSwitcherEnabled,
        currentOddsType: computedOddsType
    };
}
