import { computed, ref, toRef } from 'vue';
import { CustomerConfig } from '@leon-hub/api-sdk';
import { BetlineFlag } from 'web/src/modules/sportline/enums/rest';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';
export function useBetlineFlagsSettings(props) {
    const { sportlineBlock } = props;
    const oddsStore = useOddsStore();
    const oddType = toRef(oddsStore, 'userOddTypeLocal');
    const doUseSportMarketTypesForMainMarkets = computed(()=>!!sportlineBlock.value?.cmsMainMarketsUsageEnabled);
    const isUsedMoreRunnersCount = computed(()=>!!sportlineBlock.value?.runnersCountDisplayEnabled);
    const doRemoveDuplicateEvents = computed(()=>!!sportlineBlock.value?.doRemoveDuplicateEvents);
    const defaultRestApiFlags = computed(()=>{
        const flags = [
            BetlineFlag.RegionFullInfo,
            BetlineFlag.UrlNamesV2
        ];
        if (doUseSportMarketTypesForMainMarkets.value) flags.push(BetlineFlag.UseSportMarketTypesForMainMarkets);
        if (isUsedMoreRunnersCount.value) flags.push(BetlineFlag.UseRunnersCount);
        if (doRemoveDuplicateEvents.value) flags.push(BetlineFlag.NoDuplicate);
        if (oddType.value && oddType.value === CustomerConfig.IS_ODDS_TYPE_AMERICAN) flags.push(BetlineFlag.AmericanOddsType);
        return flags;
    });
    const searchRestApiFlags = ref([
        BetlineFlag.RegionFullInfo,
        BetlineFlag.UrlNamesV2
    ]);
    const sportsRestApiFlags = ref([
        BetlineFlag.UrlNamesV2
    ]);
    return {
        defaultRestApiFlags,
        searchRestApiFlags,
        sportsRestApiFlags
    };
}
