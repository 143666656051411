import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { usePostMatchHeadlineInfo } from '../../composables';
import HeadlineTeam from './components/HeadlineTeam.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostMatchInfo',
    props: {
        competitors: {},
        totalScore: {},
        penaltyScore: {},
        kickoff: {}
    },
    setup (__props) {
        const props = __props;
        const kickoff = toRef(props, 'kickoff', null);
        const { date } = usePostMatchHeadlineInfo({
            kickoff
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['headline-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['headline-info__stage'],
                        _ctx.$style['headline-info__stage--post-match']
                    ])
                }, [
                    _createElementVNode("label", {
                        class: _normalizeClass(_ctx.$style['headline-info__stage-completed-line'])
                    }, _toDisplayString(_ctx.$t('WEB2_SPORT_EVENT_FINISHED')), 3)
                ], 2),
                _unref(date) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
                        _ctx.$style['headline-info__date'],
                        _ctx.$style['headline-info__date--gap-after']
                    ])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['headline-info__day'])
                    }, _toDisplayString(_unref(date)), 3)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['headline-info__score-line'])
                }, [
                    _createVNode(HeadlineTeam, {
                        position: "left",
                        role: "host",
                        name: _ctx.competitors.host.name,
                        score: _ctx.totalScore.host
                    }, null, 8, [
                        "name",
                        "score"
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['headline-info__divider'])
                    }, null, 2),
                    _createVNode(HeadlineTeam, {
                        position: "right",
                        role: "guest",
                        name: _ctx.competitors.guest.name,
                        score: _ctx.totalScore.guest
                    }, null, 8, [
                        "name",
                        "score"
                    ])
                ], 2),
                _ctx.penaltyScore ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([
                        _ctx.$style['headline-info__stage'],
                        _ctx.$style['headline-info__stage--penalty-score']
                    ])
                }, [
                    _createElementVNode("label", {
                        class: _normalizeClass(_ctx.$style['headline-info__penalty-label'])
                    }, _toDisplayString(_ctx.$t('WEB2_SPORT_EVENT_PENALTY_SHOOTOUT_SCORE')) + ":", 3),
                    _createElementVNode("label", {
                        class: _normalizeClass(_ctx.$style['headline-info__penalty-score-line'])
                    }, _toDisplayString(_ctx.penaltyScore.host) + " : " + _toDisplayString(_ctx.penaltyScore.guest), 3)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PostMatchInfo'
                ]
            ]);
        };
    }
});
