export var BetlineType;
var BetlineType1;
(BetlineType1 = BetlineType || (BetlineType = {}))["All"] = "all", BetlineType1["ExtraLive"] = "inplayall", BetlineType1["Live"] = "inplay", BetlineType1["Outright"] = "outright", BetlineType1["Prematch"] = "prematch", BetlineType1["Planed"] = "planed", BetlineType1["Virtual"] = "virtual", BetlineType1["UpcomingLive"] = "inplayupcoming", BetlineType1["UpcomingExtraLive"] = "inplayupcomingall";
export var BetlineFlag;
var BetlineFlag1;
(BetlineFlag1 = BetlineFlag || (BetlineFlag = {}))["WithSportMarketGroups"] = "smg", BetlineFlag1["UseSportMarketTypesForMainMarkets"] = "mm2", BetlineFlag1["RegionFullInfo"] = "reg", BetlineFlag1["UseRunnersCount"] = "rrc", BetlineFlag1["NoDuplicate"] = "nodup", BetlineFlag1["UrlNamesV2"] = "urlv2", BetlineFlag1["OutrightExtendedMarketLayout"] = "outv2", BetlineFlag1["AmericanOddsType"] = "AMERICAN_ODDS_TYPE";
export var BetlineOrder;
var BetlineOrder1;
(BetlineOrder1 = BetlineOrder || (BetlineOrder = {}))["Structured"] = "STRUCTURED", BetlineOrder1["Unsorted"] = "UNSORTED", BetlineOrder1["SportLeagueKickoff"] = "sport-league-kickoff", BetlineOrder1["SportKickoff"] = "sport-kickoff", BetlineOrder1["SportThenKickoff"] = "SPORT_THEN_KICKOFF";
export var CompetitorType;
var CompetitorType1;
(CompetitorType1 = CompetitorType || (CompetitorType = {}))["Host"] = "HOME", CompetitorType1["Guest"] = "AWAY";
export var LiveStatusTimeDirection;
var LiveStatusTimeDirection1;
(LiveStatusTimeDirection1 = LiveStatusTimeDirection || (LiveStatusTimeDirection = {}))["Direct"] = "DIRECT", LiveStatusTimeDirection1["Reverse"] = "REVERSE";
export var BetlineSportEventStatus;
var BetlineSportEventStatus1;
(BetlineSportEventStatus1 = BetlineSportEventStatus || (BetlineSportEventStatus = {}))["OPEN"] = "OPEN", BetlineSportEventStatus1["SUSPENDED"] = "SUSPENDED", BetlineSportEventStatus1["CLOSED"] = "CLOSED", BetlineSportEventStatus1["PASSIVATED"] = "PASSIVATED", BetlineSportEventStatus1["DISABLED"] = "DISABLED";
export var BetlineSportWidgetType;
var BetlineSportWidgetType1;
// @TODO merge width { WidgetType } from '@leon-hub/api-sdk';
(BetlineSportWidgetType1 = BetlineSportWidgetType || (BetlineSportWidgetType = {}))["BETRADAR"] = "BETRADAR", BetlineSportWidgetType1["LSPORTS"] = "LSPORTS", BetlineSportWidgetType1["BET_GENIUS"] = "BETGENIUS", BetlineSportWidgetType1["NONE"] = "NONE";
export const BetlineSelectionTag = {
    YES: 'YES',
    NO: 'NO',
    UNDER: 'UNDER',
    OVER: 'OVER',
    ODD: 'ODD',
    EVEN: 'EVEN',
    HOME: 'HOME',
    DRAW: 'DRAW',
    AWAY: 'AWAY',
    NEITHER: 'NEITHER',
    BOTH: 'BOTH',
    COMPETITOR: 'COMPETITOR',
    RANGE: 'RANGE',
    SCORE: 'SCORE',
    OTHER: 'OTHER',
    HOMEDRAW: 'HOMEDRAW',
    HOMEAWAY: 'HOMEAWAY',
    DRAWAWAY: 'DRAWAWAY'
};
export var BetlineStatisticWinnerType;
var BetlineStatisticWinnerType1;
// eslint-disable-next-line @typescript-eslint/no-redeclare
(BetlineStatisticWinnerType1 = BetlineStatisticWinnerType || (BetlineStatisticWinnerType = {}))["Host"] = "HOME", BetlineStatisticWinnerType1["Guest"] = "AWAY", BetlineStatisticWinnerType1["Draw"] = "DRAW";
export var BetlineStatisticCardType;
var BetlineStatisticCardType1;
(BetlineStatisticCardType1 = BetlineStatisticCardType || (BetlineStatisticCardType = {}))["Yellow"] = "Yellow", BetlineStatisticCardType1["Red"] = "Red", BetlineStatisticCardType1["YellowRed"] = "Yellow/red";
export var BetlineStatisticScoresType;
var BetlineStatisticScoresType1;
(BetlineStatisticScoresType1 = BetlineStatisticScoresType || (BetlineStatisticScoresType = {}))["HalfTime"] = "HT", BetlineStatisticScoresType1["FullTime"] = "FT", BetlineStatisticScoresType1["OverTime"] = "OT", BetlineStatisticScoresType1["StopTime"] = "ST", BetlineStatisticScoresType1["AfterPenalty"] = "AP", BetlineStatisticScoresType1["Sets"] = "Sets", BetlineStatisticScoresType1["Winner"] = "Winner";
export var BetlineStatisticMatchStatus;
var BetlineStatisticMatchStatus1;
// also can include
// Part_1 = '1',
// Part_2 = '2',
// Part_3 = '3',
// ...
// up to Part_9 in Baseball
(BetlineStatisticMatchStatus1 = BetlineStatisticMatchStatus || (BetlineStatisticMatchStatus = {}))["MOVED"] = "60", BetlineStatisticMatchStatus1["COMPLETED"] = "100", BetlineStatisticMatchStatus1["COMPLETED_ON_PENALTY"] = "120";
