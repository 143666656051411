import { isString } from '@leon-hub/guards';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';
export function createSportsListTab(options) {
    const { sport, selectedSportFamily, shownSportFamily, transitionId } = options;
    const key = isString(sport) ? sport : sport.id;
    const isActive = isSportFamilyEquals(sport, selectedSportFamily);
    const isShown = transitionId ? transitionId === key : isSportFamilyEquals(sport, shownSportFamily);
    return {
        isActive,
        isShown,
        key
    };
}
