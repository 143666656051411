/* eslint-disable class-methods-use-this */ import { isOptionalString } from '@leon-hub/guards';
import { findSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import findSelectedByDefaultVirtualSportFilterOption from 'web/src/modules/sportline/submodules/virtual-sport/utils/findSelectedByDefaultVirtualSportFilterOption';
let VirtualSportPageSeoController = class VirtualSportPageSeoController extends DefaultPageSeoController {
    getSeoConfig(router, from, to) {
        return new Promise((resolve)=>{
            const virtualSportStore = useVirtualSportStore();
            isOptionalString(to.params.sport);
            const filter = {
                urlName: to.params.sport
            };
            const optionForNavigation = findSelectedVirtualSportFilterOption(virtualSportStore.filterOptions, filter) || findSelectedByDefaultVirtualSportFilterOption(virtualSportStore.filterOptions);
            if (optionForNavigation) {
                const params = {
                    ...to.params || {},
                    id: optionForNavigation.id,
                    sport: optionForNavigation.sport
                };
                resolve({
                    ...to,
                    params
                });
            }
        });
    }
};
export { VirtualSportPageSeoController as default };
