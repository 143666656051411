import { defineStore } from 'pinia';
import { ref, toRef, watch, computed } from 'vue';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { CustomerConfig } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { getStringValueFromOddTypeConfig } from '../utils/getStringValueFromOddTypeConfig';
const useOddsStore = defineStore('sport-odds-store', ()=>{
    const localStorageManager = useLocalStorageManager();
    const { $translate } = useI18n();
    const userStore = useUserStore();
    const { isLoggedIn } = useIsLoggedIn();
    const customerOddTypeConfig = toRef(useCustomerDataStore(), 'customerOddTypeConfig');
    const oddsList = computed(()=>[
            {
                id: 1,
                label: $translate('DECIMAL_ODDS_TYPE').value,
                oddType: CustomerConfig.IS_ODDS_TYPE_CLASSIC
            },
            {
                id: 2,
                label: $translate('AMERICAN_ODDS_TYPE').value,
                oddType: CustomerConfig.IS_ODDS_TYPE_AMERICAN
            }
        ]);
    const userOddTypeLocal = ref();
    function setCustomerOdd(currentOdd) {
        localStorageManager.setItem('odd-type', currentOdd);
        userOddTypeLocal.value = currentOdd;
        currentOdd === CustomerConfig.IS_ODDS_TYPE_CLASSIC ? updateOddTypeForUser(CustomerConfig.IS_ODDS_TYPE_CLASSIC) : updateOddTypeForUser(CustomerConfig.IS_ODDS_TYPE_AMERICAN);
    }
    async function updateOddTypeForUser(customerConfigOdd) {
        if (isLoggedIn.value && customerOddTypeConfig.value !== customerConfigOdd) await userStore.setAdditionalPropsUser({
            config: customerConfigOdd,
            value: {
                value: getStringValueFromOddTypeConfig(customerConfigOdd)
            }
        });
    }
    const currentOddsType = computed(()=>getStringValueFromOddTypeConfig(userOddTypeLocal.value));
    function initializeOddType() {
        const existingType = localStorageManager.getItem('odd-type');
        if (existingType) userOddTypeLocal.value = existingType;
        else {
            localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_CLASSIC);
            userOddTypeLocal.value = CustomerConfig.IS_ODDS_TYPE_CLASSIC;
        }
    }
    initializeOddType();
    watch(isLoggedIn, (newValue)=>{
        if (newValue) userOddTypeLocal.value = customerOddTypeConfig.value;
    });
    watch(customerOddTypeConfig, (newCustomerConfig)=>{
        if (isLoggedIn.value) userOddTypeLocal.value = newCustomerConfig;
    });
    return {
        oddsList,
        userOddTypeLocal,
        currentOddsType,
        setCustomerOdd
    };
});
export default useOddsStore;
