import { SportSegmentId } from 'web/src/modules/sportline/enums';
const defaultSegment = {
    id: SportSegmentId.Default
};
const cyberSportSegment = {
    id: SportSegmentId.CyberSport
};
const extraSegments = {
    virtual: {
        id: SportSegmentId.Default,
        isVirtual: true
    }
};
export const sportSegments = {
    default: defaultSegment,
    cyberSport: cyberSportSegment,
    ...extraSegments
};
