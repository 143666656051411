import { computed } from 'vue';
export function useSportlineEventChampionshipName(props) {
    const { sportlineEvent } = props;
    const championshipName = computed(()=>sportlineEvent.value.championshipName);
    const outrightName = computed(()=>sportlineEvent.value.outrightName);
    return {
        championshipName,
        outrightName
    };
}
