import { computed } from 'vue';
import { TimerMode, WidgetType } from '@leon-hub/api-sdk';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { sportSegments } from 'web/src/modules/sportline/constants';
import { BetlineSportWidgetType } from 'web/src/modules/sportline/enums/rest';
function mapToBetlineWidgetType(widgetType) {
    switch(widgetType){
        case WidgetType.BETRADAR:
            return BetlineSportWidgetType.BETRADAR;
        case WidgetType.LSPORTS:
            return BetlineSportWidgetType.LSPORTS;
        case WidgetType.BETGENIUS:
            return BetlineSportWidgetType.BET_GENIUS;
        default:
            return;
    }
}
function getDefaultSportRepresentationSettings() {
    return new Map();
}
/** Core options for sportline adapters */ export function useBuildSportSettings(props) {
    const { sportlineBlock } = props;
    const isZeroMarginEnabled = computed(()=>!!sportlineBlock.value?.zeroMarginEnabled);
    const isCybersportV2Enabled = computed(()=>!!sportlineBlock.value?.cybersportV2Enabled);
    const sportConfiguration = computed(()=>sportlineBlock.value?.sportConfigurationV2 ?? []);
    const sportUrlMapping = computed(()=>sportlineBlock.value?.sportUrlMapping ?? []);
    // Mapping for URLs
    const sportFamilyMapping = computed(()=>sportUrlMapping.value.reduce((result, config)=>({
                ...result,
                [config.sportId]: config.sportUrl?.toLowerCase()
            }), {}));
    const sportSegmentMapping = computed(()=>{
        const result = {};
        if (isCybersportV2Enabled.value) result[SportFamily.ESport] = sportSegments.cyberSport;
        if (sportlineBlock.value?.virtualSportLiveEventsEnabled && sportSegments.virtual) {
            result[SportFamily.VirtualSoccer] = sportSegments.virtual;
            result[SportFamily.VirtualSoccerV2] = sportSegments.virtual;
            result[SportFamily.VirtualBasketball] = sportSegments.virtual;
            result[SportFamily.VirtualTennis] = sportSegments.virtual;
            result[SportFamily.VirtualTennisInplay] = sportSegments.virtual;
            result[SportFamily.VirtualCricket] = sportSegments.virtual;
        }
        return result;
    });
    const sportRepresentationSettings = computed(()=>Object.fromEntries(sportConfiguration.value.reduce((result, config)=>result.set(config.sportFamily, {
                useFullProgress: config.config.timerMode === TimerMode.SECONDS,
                defaultWidgetType: mapToBetlineWidgetType(config.config.widgetType)
            }), getDefaultSportRepresentationSettings()).entries()));
    const parseSportlineSettings = computed(()=>({
            sportSegmentMapping: sportSegmentMapping.value,
            sportFamilyMapping: sportFamilyMapping.value,
            sportRepresentationSettings: sportRepresentationSettings.value,
            canUseZeroMargin: isZeroMarginEnabled.value
        }));
    return {
        sportFamilyMapping,
        isZeroMarginEnabled,
        isCybersportV2Enabled,
        parseSportlineSettings
    };
}
